/*=========================
BASIC CSS 
=========================*/

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
}


:root {
  --color5: #5A5A5A;
  --color4: #FFFFFF;
  --primary: #07A2BB;
  --secondary: #048D81;
  --color3: #EFE8E8;
  --textos: #707070;
  --color2: #0E409B;
  --color1: #753A51;
}

body,
html {
  margin: 0;
  padding: 0;
}

h2 {
  font-weight: 500;
}

div#structure {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
}

aside#menu {
  width: 300px;
  height: 100%;
  z-index: 99991;
  background-color: #ffffff;
  box-shadow: 0 0 4px #00000026;
  display: flex;
  flex-direction: column;
}

div#top_bar {
  height: 60px;
  background: #F9F9FB;
  box-shadow: 0 0 4px #00000026;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  gap: 10px;
  font-size: 12px;
  z-index: 9999;
}

div#main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#main-content {
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  background: #F9F9FB;
  overflow: auto;
}

div#logo {
  text-align: center;
  padding: 20px 35px;
  font-size: 45px;
  color: #00BCD4;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.menu_button_container {
  width: 100%;
}


.menu_button_container a, .drop_menu li {
  text-decoration: none;
  color: #454545;
  gap: 10px;
  justify-content: left;
  padding: 10px 20px;
  text-transform: unset;
  border-bottom:solid 1px #ddd;
}

.drop_menu a {
  text-decoration: none;
  color: #454545;
  gap: 10px;
  justify-content: left;
  text-transform: unset;
}

.menu_button_container a:last-child, .drop_menu li:last-child {
  border:none !important;
}

button#menu_button {
  min-width: unset;
  padding: 0;
}


.flex_container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}


.card {
  background: #fff;
  padding: 40px;
  border-radius: 5px;
  color: #6b6b6b;
  font-weight: 300;
  position: relative;
  box-shadow: 0 0 30px #00000012;
  overflow: hidden;
}

.card_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 13px;
  text-transform: uppercase;
  margin: 0;
}

.card_subtitle {
  color: #B5B5C3;
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}

b {
  font-weight: 500;
}

.input_group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}


.input_row {
  display: flex;
  gap: 8px;
}

.input_row_wrap {
  flex-wrap: wrap;
}

.box {
  background: #fff;
  padding: 40px;
  border-radius: 5px;
  color: #6b6b6b;
  font-weight: 300;
  position: relative;
  box-shadow: 0 0 30px #00000012;
  width: fit-content;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 1px #bfbfbf;
  background: #fff;
}

.box_header {
  margin-bottom: 30px;
}

.box_content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.box_header {
  margin-bottom: 30px;
}

.input_group h2 {
  font-weight: 500;
  margin: 0;
}

.buttons_top {
  gap: 8px;
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-end;
}

div#loader {
  justify-content: center;
  display: flex;
}

.title {
  font-weight: 300;
  font-size: 20px;
}

.subtitle {
  font-size: 11px;
}

.box_editor {
  padding-bottom: 90px;
}

.forced_box_editor {
  padding-bottom: 90px !important;
}

.MenuItem {
  display: flex;
  padding: 10px;
}

.MenuActive {
  background: var(--primary);
}

.MenuActive a {
  color: #fff;
}

.menu_button_container span {
  font-size: 12px;
  line-height: normal;
}

.inputColumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.btn_drop_menu {
  text-decoration: none;
  color: #454545 !important;
  gap: 10px;
  justify-content: left !important;
  padding: 10px 20px !important;
  text-transform: unset !important;
  border-bottom: solid 1px #ddd !important;
}

/*========================= 
END BASIC CSS
=========================*/


.drawerImagesContainerDiv {
  display: flex;
  width: 120px;
  height: 114px;
  justify-content: center;
  align-items: center;
  border: dashed 2px #ddd;
  position: relative;
}

.drawerImagesContainerDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropzone_active {
  border-color: var(--primary);
}


.ListImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.flexWImage_right {
  font-size: 0.8125rem;
}

.flexWImage {
  display: flex;
  align-items: center;
  gap: 10px;
}

#dropFileDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}

.showOnlyOnHover {
  opacity: 0;
}

.showOnlyOnHover:hover {
  opacity: 1;
  background: #ffffffdb;
}


div#multimediaFileDropBox {
  width: 100%;
  max-width: 500px;
}

div#multimediaFileDropBox span {
  max-width: 100%;
  overflow-wrap: break-word;
  padding: 20px;
  text-align: center;
}

span.fileUploaded {
  display: flex;
  align-items: center;
}


.hasFile, .hasFile span {
  border-color: var(--primary)!important;
  color: var(--primary)!important;
}

div#loginBox {
    margin: auto;
    max-width: 90%;
    text-align: center;
    width: 400px;
}

.loginContainer {
    position: fixed;
}
#dropFileDiv, .loginContainer {
    display: flex;
    height: 100%;
    width: 100%;
}

.form, .input_group, .input_row {
    display: flex;
    flex-direction:column;
    gap: 8px;
}

div#user_editor {
  min-width: 500px;
}

table td:first-child {
  cursor: pointer;
}

#textos-liturgicos-chip span {
  font-size: 12px;
}

#textos_liturgicos_editor .input_column {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}

#textos_liturgicos_editor .input_row .input_label_group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px auto;
}

#textos_liturgicos_editor .input_row .input_label_group p {
  margin-bottom: 0px;
}

#textos_liturgicos_editor .input_row .input_label_group .editor-shell .editor-scroller {
  height: 110px;
}

#textos_liturgicos_editor .input_row .input_label_group .editor-shell {
  margin: 0;
}

#textos_liturgicos_editor .input_row .input_label_group .editor-shell {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

#textos_liturgicos_editor .input_row .input_label_group .editor-shell {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

#textos_liturgicos_editor .input_row .input_label_group .editor-shell .editor-container {
  border-radius: 5px !important;
}

#textos_liturgicos_editor .input_row .input_label_group .editor-shell .toolbar {
  border-bottom: solid 1px lightgray;
}

.forced_box_editor .filter {
  margin: 7px auto 5px;
}

.filters_container {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.filter_group {
  display: flex;
  flex-direction: column;
}

#menu {
  overflow: hidden;
}

.menu_button_container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% + 17px);
  left: 0;
  right: -17px;
  overflow-y: auto;
}